<template>
  <el-card class="box-card">
    <div class="goods-form">
      <div class="sctp-pad-tb20 sctp-bg-white sctp-mar-b10 sctp-pad-lr20">
        <el-tabs :before-leave="tableChange" v-model="addJobTab" type="card">
          <el-tab-pane label="基本信息" name="1">
            <div style="width:600px">
              <el-form ref="formData" :model="formData" :rules="formDataRules" label-width="120px">
                <input type="hidden" v-model="jobid"/>
                <el-form-item label="头像上传" prop="imgurl">
                  <el-upload
                    :http-request="fileUploadFunction"
                    :data="{...uploadEnum.JOB}"
                    action=""
                    :show-file-list="false"
                    :on-error="uploadFileError"
                    :before-upload="beforeUpload"
                    :on-success="imgurlUploadSuccess">
                    <template v-if="picture.imgurl">
                      <div @click.prevent="()=>{}">
                        <el-image
                          :src="picture.imgurl"
                          fit="cover"
                          style="width: 80px; height: 80px"
                        ></el-image>
                      </div>
                    </template>
                    <el-button v-else size="small" type="primary">上传头像</el-button>
                  </el-upload>
                </el-form-item>
                <el-form-item class="small" label="兼职岗位" prop="president">
                  <el-input v-model="formData.president" maxlength="40">
                    <span slot="append">还可输入<span
                      class="sctp-red">{{
                        40 - (formData.president && formData.president.length || 0)
                      }}</span>字</span>
                  </el-input>
                </el-form-item>
                <el-form-item prop="jobtype" :required="true" label="兼职类型">
                  <el-select
                    @change="formData.jobsubtype=null"
                    v-model="formData.jobtype" placeholder="请选择"
                  >
                    <el-option
                      v-for="item in JobTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="formData.jobsubtype" :required="true" placeholder="请选择"
                    @change="queryTypeid()"
                  >
                    <el-option
                      v-for="item in JobSubTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="inline-form" label="期望薪水" prop="expectedSalary">
                  <el-input type="number" v-model="formData.expectedSalary"></el-input>
                  <span>&emsp;&emsp;单位:</span>
                  <el-select v-model="formData.salaryUnit" placeholder="">
                    <el-option
                      v-for="item in unitOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="可工作时间" prop="jobtime">
                  <el-radio-group v-model="formData.jobtime">
                    <el-radio :label="1">全部</el-radio>
                    <el-radio :label="2">工作日</el-radio>
                    <el-radio :label="3">周末</el-radio>
                    <el-radio :label="4">节假日</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="工作方式" prop="jobmode">
                  <el-radio-group v-model="formData.jobmode">
                    <el-radio :label="1">远程在家</el-radio>
                    <el-radio :label="2">偶尔到岗</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="最擅长技能">
                  <el-tag class="tag_sk" type="success"
                          v-for="usertag in JobUserSkillList"
                          closable
                          :key="usertag.id"
                          :disable-transitions="false"
                          @close="handleClose(usertag)">
                    {{ usertag.skillname }}
                  </el-tag>
                  <el-popover
                    v-model="colseflag"
                    placement="right"
                    width="400"
                    trigger="click">
                    <el-tag class="tag_sk"
                            v-for="skill in JobSkillList"
                            :key="skill.id"
                            :disable-transitions="false" v-on:click="chooseSkill(skill)">
                      <a>{{ skill.skillname }}</a>
                    </el-tag>
                    <el-tag slot="reference" class="tag_sk" type="info"><a>#添加</a></el-tag>
                  </el-popover>

                </el-form-item>
                <el-form-item label="工作年限" prop="workingYears">
                  <el-select v-model="formData.workingYears" placeholder="">
                    <el-option
                      v-for="item in workingYearsList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span>年</span>
                </el-form-item>
                <el-form-item label="自我介绍" prop="aboutMyself">
                  <el-input type="textarea" :rows="5" maxlength="100" placeholder="请输入自我介绍，最多100个字符"
                            :show-word-limit="true"
                            v-model="formData.aboutMyself"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="sctp-square" size="small" @click="$router.back()">返回</el-button>
                  <el-button class="sctp-square" size="small" type="primary"
                             @click="changeTab('1', '2')">下一步
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="兼职介绍" name="2">
            <el-form ref="introduceDetailForm" :model="formData" :rules="formDataRules"
                     label-width="120px">
              <el-form-item label="兼职介绍" prop="introduceDetail">
                <ckeditor v-model="formData.introduceDetail"></ckeditor>
              </el-form-item>
            </el-form>
            <div class="sctp-mar-tb15">
              <el-button class="sctp-square" size="small" @click="changeTab('2', '1')">上一步
              </el-button>
              <el-button class="sctp-square" size="small" type="primary"
                         @click="changeTab('2', '3')">下一步
              </el-button>
            </div>
            <!--兼职介绍-->
          </el-tab-pane>
          <el-tab-pane label="作品集" name="3">
            <el-table ref="dataTable"
                      @selection-change="selectionChange"
                      :data="works"
            >
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="worksname"
                label="作品名"
                width="180">
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true"
                               align="center"
                               height="100"
                               label="详细内容">
                <template slot-scope="scope">
                  <div class="rich-text" style="width: 100px;max-height: 50px;overflow: hidden"
                       v-html="scope.row.details"></div>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="apkurl"
                label="应用">
              </el-table-column>
              <el-table-column
                align="center"
                width="100px"
                label="创建时间">
                <template slot-scope="scope">
                  {{ dateToString(scope.row.createtime) }}
                </template>
              </el-table-column>
            </el-table>
            <div class="sctp-mar-tb15">
              <el-button class="sctp-square" size="small" @click="changeTab('3', '2')">上一步
              </el-button>
              <el-button :loading="submitting" class="sctp-square" size="small" type="primary"
                         @click="onSubmit">提交
              </el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-card>
</template>

<script>
import validator from '../../../resources/js/async-validator'
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../resources/js/config');
export default {
  props: ['id'],
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
  },
  data() {
    const checkNumberCanNull = (rule, value, callback) => {
      if (value) {
        validator.validNum(rule, value, callback)
      } else {
        callback()
      }
    };
    return {
      submitting: false,
      uploadEnum,
      jobid: null,
      works: [],
      userWorks: [],
      userWorksId: [],
      addJobTab: '1',
      picture: {
        imgurl: null,
      },
      colseflag: false,
      JobTypeList: [],
      JobSkillList: [],
      JobUserSkillList: [],
      JobUserSkillIdList: [],
      formData: {
        president: null,
        jobtype: null,
        jobsubtype: null,
        provinceid: null,
        cityid: null,
        expectedSalary: null,
        salaryUnit: '月',
        jobtime: null,
        jobmode: null,
        workingYears: null,
        aboutMyself: null,
        introduceDetail: null,
        imgurl: null, // 预览图
      }, unitOptions: [
        {label: '按小时', value: '小时'},
        {label: '按天', value: '天'},
        {label: '按月', value: '月'},
        {label: '按工作量', value: '按工作量'},
        {label: '按项目', value: '按项目'},
        {label: '按次', value: '次'},
        {label: '商议', value: '商议'},
      ], workingYearsList: [
        {label: '一年以内', value: '一年以内'},
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
        {label: '5', value: '5'},
        {label: '6', value: '6'},
        {label: '7', value: '7'},
        {label: '8', value: '8'},
        {label: '9', value: '9'},
        {label: '10', value: '10'},
        {label: '十年以上', value: '十年以上'},
      ],
      formDataRules: {
        imgurl: [
          {required: true, message: '请上传兼职头像', trigger: ['blur', 'change']},
        ],
        president: [
          {required: true, message: '请输入兼职岗位', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (!value){
                callback(new Error('请输入兼职岗位名称'))
              } else if (!/^\S+$/.test(value)) {
                callback(new Error('兼职名称不能输入空格'))
              } else {
                callback()
              }
            }
          }
        ],
        jobtype: [
          {required: true, message: '请选择一级职业类型', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (!this.formData.jobsubtype) {
                callback(new Error('请选择二级职业类型'));
              } else {
                callback();
              }
            }
          },
        ],
        jobsubtype: [
          {required: true, message: '请选择二级职业类型', trigger: ['blur', 'change']},
        ],
        expectedSalary: [
          {required: true, message: '请输入期望薪水', trigger: 'change'},
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('期望薪资不能为负数'))
              } else {
                callback()
              }
            },
          }
        ],
        salaryUnit: [
          {required: true, message: '请选择期望薪水单位', trigger: 'change'},
        ],
        jobtime: [
          {required: true, message: '请选择可工作时间', trigger: 'change'},
        ],
        jobmode: [
          {required: true, message: '请选择工作方式', trigger: 'change'},
        ],
        workingYears: [
          {required: true, message: '请选择工作年限', trigger: 'change'},
        ],
        aboutMyself: [
          {required: true, message: '请输入自我介绍', trigger: 'change'},
        ],
        introduceDetail: [
          {required: true, message: '请输入兼职介绍', trigger: 'change'},
        ],
      }, tableChange: (activeName, oldActiveName) => {
        this.changeTab(oldActiveName, activeName);
      },
      imgurlImgLimit: 2,
    }
  },
  mounted() {
  },
  methods: {
    uploadFileError() {
      this.$message.error('文件上传失败');
    },
    beforeUpload(file) {
      const isImage = file.type.startsWith("image");
      const isLtSize = file.size / 1024 / 1024 < 1;
      if (!isImage) {
        this.$message.error('只能上传图片格式!');
      }
      if (!isLtSize) {
        this.$message.error('图片大小不能超过1MB!');
      }
      return isImage && isLtSize && this.$message('文件正在上传') && true;
    },
    imgurlUploadSuccess(res) {
      if (res.code) {
        let {size, src} = res;
        this.formData.imgurl = src;
        this.picture.imgurl =  src;
        this.$message.success('上传文件成功');
      } else {
        this.$message.error('上传文件失败');
      }
      this.$refs['formData'].validateField('imgurl');
    },
    imgurlRemove(file) {
      if (file.response || file.url) {
        let path = file.response && file.response.src || file.url;
        path = path.substring(path.indexOf('/upload/'));
        let index = this.formData.imgurl.findIndex(item => item === path);
        this.formData.imgurl.splice(index, 1);
        this.picture.imgurl.splice(index, 1);
      }
    },
    changeTab(currentTab, targetTab) {
      let currentTabInt = parseInt(currentTab)
        , targetTabInt = parseInt(targetTab);
      let check1 = () => {
        return this.$refs['formData'].validate()
        .catch(() => {
          this.$message.warning('请完成表单');
          return Promise.reject('1');
        });
      };
      let check2 = () => {
        return this.$refs['introduceDetailForm'].validate().catch(() => {
          this.$message.warning('请完成表单');
          return Promise.reject('2');
        });
      };
      let check3 = () => {
        return this.$refs['WorksForm'].validate().catch(() => {
          this.$message.warning('请完成表单');
          return Promise.reject('3');
        });
      };
      if (targetTabInt < currentTabInt) {
        // 如果是上一步，直接通过
        this.addJobTab = targetTab;
      } else {
        let checkArray = null;
        if (targetTabInt > 2) {
          checkArray = () => {
            return [check1(), check2()];
          }
        } else if (targetTabInt > 1) {
          checkArray = () => {
            return [check1()];
          }
        } else {
          this.addJobTab = targetTab;
        }
        if (checkArray) {
          return Promise.all(checkArray()).then(res => {
            this.addJobTab = targetTab;
          }).catch((err) => {
            this.addJobTab = err;
            return Promise.reject();
          });
        }
      }
    },
    /**
     * 提交表单数据.
     */
    submitFormData() {
      let formData = this.goodsForm;
      console.log(formData);
      this.submitting = true;
      this.$request.post({
        reqcode: '1143',
        reqdesc: '编辑兼职',
        president: this.formData.president,
        jobtype: this.formData.jobtype,
        jobsubtype: this.formData.jobsubtype,
        expectedSalary: this.formData.expectedSalary,
        salaryUnit: this.formData.salaryUnit,
        jobtime: this.formData.jobtime,
        jobmode: this.formData.jobmode,
        workingYears: this.formData.workingYears,
        aboutMyself: this.formData.aboutMyself,
        introduceDetail: this.formData.introduceDetail,
        JobUserSkillIdList: this.JobUserSkillIdList,
        userWorks: this.userWorks.map(item => item.id),
        imgurl: this.formData.imgurl,
        userid: this.user.userId,
        jobid: this.jobid
      }).then(res => {
        this.$message.success('保存成功');
        this.$router.push('/user/job');
      }).finally(() => {
        this.submitting = false;
      });
    },
    onSubmit() {
      this.submitFormData();
    },
    selectionChange(selectedArray) {
      this.userWorks = selectedArray;
    },
    queryWorks() {
      this.$request.post({
        reqcode: '1127',
        reqdesc: '获取作品集',
        userid: this.user.userId
      }).then(res => {
        const {data = [], count = 0} = res;
        this.works = data;
        //this.pageConfig.total = count
      })
    },
    queryTypeid(flag) {
      this.$request.post({
        reqcode: '1160',
        reqdesc: '获取类型技能',
        typeid: this.formData.jobsubtype
      }).then(res => {
        const {data = [], count = 0} = res;
        this.JobSkillList = data;
        if (flag != 'false') {
          this.JobUserSkillList = [];
          this.JobUserSkillIdList = [];
        }
      })
    },
    queryJobType() {
      this.$request.post({
        reqcode: '1142',
        reqdesc: '获取兼职类型和技能类型',
        userid: this.user.userId
      }).then(res => {
        const {data = [], count = 0} = res;
        this.JobTypeList = data.types;
      })
    },
    handleClose(tag) {
      this.JobUserSkillList.splice(this.JobUserSkillList.indexOf(tag), 1);
    },
    chooseSkill(skill) {
      console.log(this.JobUserSkillList.length);
      if (this.JobUserSkillList.length > 2) {
        this.$message.error(`最多选择三个技能`);
      } else {
        let flag = this.JobUserSkillList.some(arr => arr.id === skill.id )
        if (flag){
          this.$message.error(`你已经选了该技能`);
          return
        }
        this.JobUserSkillList.push(skill);
        this.JobUserSkillIdList.push(skill.id);
      }
      this.colseflag = false;
    },
    reselectFun(dataArr, IDArr) {
      this.$nextTick(function () {
        dataArr.forEach(row => {
          if (IDArr.includes(row.id)) {
            this.$refs.dataTable.toggleRowSelection(row, true);
          }
        });
      });
    },
    getJobDetail() {
      if (!this.jobid) {
        return
      }

      this.$request.post({
        reqcode: '1144',
        reqdesc: '我的兼职',
        userId: this.user.userId,
        jobid: this.jobid
      }).then(res => {
        const {data} = res;
        if (data.length > 0) {
          let target = data[0];
          this.formData = target;
          this.picture.imgurl =  target.imgurl;
          // this.fileList.push(
          //   [{
          //     url: this.fullUrlPath(target.imgurl),
          //     name: target.imgurl.substring(target.imgurl.lastIndexOf('/') + 1)
          //   }]
          // );
          this.queryTypeid("false");
          this.JobUserSkillList = target.jobUserSkillIdList;
          target.jobUserSkillIdList.forEach(skill => {
            this.JobUserSkillIdList.push(skill.id)
          });
          target.userworks.forEach(ids => {
            this.userWorksId.push(ids.id)
          });
          this.userWorks = target.userworks;

          this.reselectFun(this.works, this.userWorksId);
        }
      })
    }
  },
  computed: {
    JobSubTypeList() {
      let options = [];
      const {formData, JobTypeList} = this;
      if (formData && formData.jobtype && JobTypeList && JobTypeList.length > 0) {
        options = JobTypeList
        .filter(one => one.id === formData.jobtype);
        if (options && options.length > 0) {
          return options[0].children
        }
      }
      return options
    }
  },
  beforeMount() {
    const {jobid} = this.getQuery();
    this.jobid = jobid;
    this.queryWorks();
    this.queryJobType();
    this.getJobDetail()
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .inline-form /deep/ .el-input {width: 180px;}
  .formData /deep/ .el-form-item.small .el-input {width: 50%!important;}
  .jobAttribute .el-checkbox {margin-right: 10px;}
  .attribute-group + .attribute-group {border-top: 1px solid #eee;}
  .tag_sk{margin-right:5px }
  .attribute-group {background-color:#f8f8f8;line-height: 2.2;padding: 10px 0;}
  /*@formatter:on*/
</style>
